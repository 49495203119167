<script>
//import vue2Dropzone from "vue2-dropzone";
//import Multiselect from "vue-multiselect";
import {
  required,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import {VueSignaturePad} from 'vue-signature-pad';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import AccountService from "@/services/AccountService";
import ClientService from "@/services/ClientService";
import MovementService from "@/services/MovementService";
import HelperJS from "@/functions/general";
import moment from "moment";
const helper = new  HelperJS();
import {  mapState } from "vuex";
import Select2 from 'v-select2-component';

import 'vue2-datepicker/locale/es';


const movementService = new MovementService();
const clientService = new ClientService();
const accountService = new AccountService();

export default {
  page: {
    title: "Movimiento",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    //vueDropzone: vue2Dropzone,
    //Multiselect,
    VueSignaturePad,
    Layout,
    Select2,
    PageHeader,
    DatePicker
  },
  data() {
    return {
      title: "Movimiento",
      value: '',
      userAccounts: [],
      prestamistas: [],
      infoPage:{
        currencys:['MXN', 'USD'],
        view: 'add',
        routeBack: '/movimientos/list',
        method_payments: [
          {
            id:1,
            text: 'Efectivo'
          },
          {
            id:2,
            text: 'Transferencia' 
          },
          {
            id:3,
            text: 'Cheque' 
          },
        ],
      },
      infoData:{
        account_name: null,
        client_name: null,
      },

      objectData:{
        id: null,
        account_id: null,
        date: null,
        currency: 'MXN',
        amount: null,
        method_payment: 1,
        type: 1,
        comment: null,
        client_id: null,
        user_id: null,
      },

      items: [
        {
          text: "Inicio"
        },
        {
          text: "Add Product",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      submitted: false,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
  },
  validations: {
      objectData: {
        client_id: {
          required,
        },
        account_id: {
          required,
        },
        date: {
          required,
        },
        amount: {
          required,
        },
        type: {
          required,
        },
        currency: {
          required,
        },
        method_payment: {
          required,
        }
      },
    },
  middleware: "authentication",
    computed: {
    /**
     * Total no. of records
     */
  ...mapState('auth', ['currentUser']),
  
  },
  created(){

    this.chargeItems();
  },
 methods: {
  undo() {
      this.$refs.signaturePad.clearSignature();
       //const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      //alert("Open DevTools see the save data.");
     /**console.log(isEmpty);
      console.log(data);*/
    },
    
    formatMoney(value){
      return helper.formatMoney(value);

    },
    formatDate(value, type='short'){
      return helper.formatDate(value, type);

    },
    chargeItems(){
      clientService.getList().then(response=>{
        let prestamistas = response.data.clients;
        prestamistas.map(item=> item.text = item.full_name);

        this.prestamistas = prestamistas;
      });
        this.items[1].text = 'Crear movimiento';

     /* if(this.$route.name==='interesesPayment'){
        this.infoPage.view = 'payment';
        this.items[1].text = 'Crear pago';
        ordenService.getById(this.$route.params.id).then(async response=>{
          let data = response.data.orden;
      
         this.objectData.date = data.cut_date;
         this.objectData.date_payment = moment().format('YYYY-MM-DD');
         this.objectData.amount = data.amount;
         this.objectData.orden_id = data.id;
         this.objectData.account_id = data.account_id;
         this.objectData.client_id = data.client_id;
         this.objectData.start_date = data.start_date;
         this.objectData.status = 1;
        this.objectData.user_id = this.currentUser.id;

         this.infoData.client_name = data.client.full_name;
         this.infoData.account_name = data.account.name;
         this.objectData.currency = data.account.currency;
        });
      }*/
      if(this.$route.name==='movementInfo'){
        this.infoPage.view = 'info';
        this.items[1].text = 'Información movimiento';
        
        movementService.getById(this.$route.params.id).then(async response=>{  
          let data = response.data.movement;
          //console.log( moment(data.date).format('DD-MMM-YYYY'),  data.date);
          this.objectData.date = moment(data.date).format('DD-MMM-YYYY');
          this.objectData.amount = data.amount;
          this.objectData.account_id = data.account_id;
          this.objectData.client_id = data.client_id;
          this.objectData.user_id = this.currentUser.id;
          this.objectData.currency = data.currency;
          this.objectData.image = data.image;
          this.objectData.method_payment = data.method_payment;
          this.objectData.comment = data.comment;
          this.objectData.id = data.id;
          this.checkAccounts();
        
         /*this.infoData.client_name = data.client.full_name;
         this.infoData.account_name = data.account.name;*/

        });



      }
      

    },
    async formSubmit() {
      //console.log('enviado', this.$v);
      this.submitted = true;
      if(this.$v.$error===false &&  this.$v.$invalid===false){
        console.log('validado');
        let formData = {...this.objectData}
        
      console.log('hola', formData, moment(formData.date, 'DD-MMM-YYYY').format('YYYY-MM-DD'));
        
        if( this.infoPage.view === 'add'){
          const { data } = this.$refs.signaturePad.saveSignature();
          formData.image = data;
          formData.user_id = this.currentUser.id;
          formData.date = moment(formData.date, 'DD-MMM-YYYY').format('YYYY-MM-DD');
          delete formData.id;
          await movementService.create(formData)
          this.successForm(this.infoPage.routeBack);
        }else{
          formData.user_id = this.currentUser.id;
          formData.date = moment(formData.date, 'DD-MMM-YYYY').format('YYYY-MM-DD');
          delete formData.client;
          delete formData.account;
          await movementService.update(formData)
          this.successForm(this.infoPage.routeBack);
        }
      }
      
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    successForm(route){
      Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
       this.$router.push({
            path: route,
          });
      });
    },
    cancelForm(){
      this.$router.push({
        path: this.infoPage.routeBack,
      });
    },
    checkAccounts(){
      console.log('hola');
      let client_id = this.objectData.client_id;
      let params = {
        client_id:client_id
      }

        accountService.getList(params).then(response=>{
          let userAccounts = response.data.accounts;
          console.log(userAccounts, 'userAccounts');
          userAccounts.map(item=> item.text = item.name);
          //console.log(userAccounts, 'text');

          this.userAccounts = userAccounts;
        });
    },
    checkCurrency(){
      let account_id = Number(this.objectData.account_id);
      let accountFinded = this.userAccounts.find(item=> item.id === account_id);
      this.objectData.currency = accountFinded.currency;
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },


};
</script>

<template>
  <Layout>
    <form  class="needs-validation" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
          
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Datos generales</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completar información del pago
                    </p>
                  </div>
                 
                </div>
              </div>
            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                 <hr class="my-4" />

        
                  

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Prestamista</label>
                        <Select2  
                         :class="{
                            'is-invalid': submitted && $v.objectData.client_id.$error,
                          }"
                        :disabled="infoPage.view==='info'" @change="checkAccounts()" 
                        class="form-control form-custom" v-model="objectData.client_id" :options="prestamistas"
                         :settings="{ settingOption: value, settingOption: value }"
                         />              
                    
                        <div
                          v-if="submitted && $v.objectData.client_id.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.client_id.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Cuenta</label>
                        <Select2  
                         :class="{
                            'is-invalid': submitted && $v.objectData.account_id.$error,
                          }"
                        :disabled="infoPage.view==='info'" @change="checkCurrency()" 
                        class="form-control form-custom" v-model="objectData.account_id" :options="userAccounts" :settings="{ settingOption: value, settingOption: value }"
                         />              
                    
                        <div
                          v-if="submitted && $v.objectData.account_id.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.account_id.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Fecha</label>
                        <date-picker
                           :disabled="infoPage.view==='info'"
                            v-model="objectData.date"
                            format="DD-MMM-YYYY"
                            value-type="DD-MMM-YYYY"
                            :first-day-of-week="1"
                            lang="es"
                            :class="{
                            'is-invalid': submitted && $v.objectData.date.$error,
                          }"
                          ></date-picker>           
                    
                        <div
                          v-if="submitted && $v.objectData.date.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.date.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                       <div class="mb-3">
                        <label for="name">Tipo</label>
                        <div class="row">
                          <div class="col-12">
                            <b-form-radio  :disabled="infoPage.view==='info'"
                          v-model="objectData.type"
                          class="mb-3 d-inline-block mrc-3"
                          value="1"
                          plain
                          >Ingreso</b-form-radio>
                        <b-form-radio  :disabled="infoPage.view==='info'"
                          v-model="objectData.type"
                          class=" d-inline-block mb-3 "
                          value="0"
                          plain
                          >Retiro</b-form-radio >
                          </div>
                        </div>
                         
                       </div>
                       
                    </div>
                     
                     <div class="col-md-4">
                       <div class="mb-3">
                        <label for="name">Monto</label>
                          <input
                          id="b_amount"
                          type="number"
                          name="b_amount"
                          class="form-control"
                          v-model="objectData.amount"
                           :disabled="infoPage.view==='info'"
                           :class="{
                            'is-invalid': submitted && $v.objectData.amount.$error,
                          }"
                        />

                         <div
                          v-if="submitted && $v.objectData.amount.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.amount.required"
                            >Campo requerido</span
                          >
                        </div>
                       </div>
                    </div>
                    <div class="col-md-4">
                       <div class="mb-3">
                        <label for="name">Método de pago</label>
                         <select class="form-control select2" v-model="objectData.method_payment" 
                          :class="{
                            'is-invalid': submitted && $v.objectData.method_payment.$error,
                          }"
                        :disabled="infoPage.view === 'info'">
                          <option :value="payment.id" v-for="payment in infoPage.method_payments" :key="payment.id" v-text="payment.text"></option>
                        </select>
                           <div
                          v-if="submitted && $v.objectData.method_payment.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.method_payment.required"
                            >Campo requerido</span
                          >
                        </div>
                       </div>
                       
                    </div>
                    <div class="col-md-4">
                       <div class="mb-3">
                        <label for="name">Moneda</label>
                         <select class="form-control select2" v-model="objectData.currency"  disabled="true"
                          :class="{
                            'is-invalid': submitted && $v.objectData.currency.$error,
                          }">
                        
                          <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
                          </select>
                             <div
                          v-if="submitted && $v.objectData.currency.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.currency.required"
                            >Campo requerido</span
                          >
                        </div>
                       </div>
                       
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3 mb-0">
                        <label for="productdesc">Comentarios</label>
                        <textarea
                        v-model="objectData.comment"
                          class="form-control"
                          id="productdesc"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                    <div class="row">
                    <div class="col-md-12">
                        <div class="mb-1 mb-0">
                          <label for="productdesc">Firma</label>
                        </div>
                      <div class="container-signature mb-3">
                        <img :src="objectData.image" alt="" style="width:100%; height:300px" 
                        v-if="infoPage.view === 'info'">
                        <VueSignaturePad  width="100%" height="300px" ref="signaturePad" v-if="infoPage.view !== 'info'" />
                   
                        
                      </div>
                      <button   class="btn btn-info ms-1 float-right" @click.prevent="undo" v-if="infoPage.view === 'add'">
                          <i class="uil uil-file-alt me-1"></i> Limpiar
                        </button>
                      </div>
                    </div>


                  
                  
              </div>
            </b-collapse>
          </div>

        
  
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger" @click.prevent="cancelForm()" >
        
          <i class="uil  me-1" :class="infoPage.view!=='info'?'uil-times':'uil-arrow-circle-left'"></i> {{infoPage.view!=='info'?'Cancelar':'Regresar'}}
        
        </a>
        
        <button   class="btn btn-success ms-1" @click.prevent="formSubmit" v-if="infoPage.view === 'add'">
          <i class="uil uil-file-alt me-1"></i> Guardar
        </button>
      </div>
      <!-- end col -->
    </div>
    </form>
  </Layout>
</template>
